
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';

  import { LogFiltersOptions } from '@/interfaces';
  import { LogModel } from '@/models';
  import { GetLogsPayload } from '@/store/interfaces';

  import LogEntry from '@/components/LogEntry.vue';
  import LogFilters from '@/components/filters/Logs.vue';
  
  const logsStore = namespace('logs');

  @Component({ components: { LogEntry, LogFilters } })
  export default class LogsPage extends Vue {
    filters: LogFiltersOptions = {};

    @logsStore.State
    readonly logs!: LogModel[];

    @logsStore.State
    readonly allLogs!: LogModel[];

    mounted(): void {
      this.getLogs({ filters: this.filters });
    }

    @logsStore.Action
    getLogs!: (data?: GetLogsPayload) => Promise<void>;

    getNextPage(): void {
      this.getLogs({ filters: this.filters, fetchMore: true });
    }

    filtersUpdated(filters: LogFiltersOptions): void {
      this.filters = { ...filters };
      this.getLogs({ filters: this.filters });
    }
  }
