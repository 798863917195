
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { LogFiltersOptions, LogType, TextValuePair } from '@/interfaces';
  import { getConceptsForLocation, getLocationsFromConceptList } from '@/helpers/concepts';
  import { ConceptModel } from '@/models';

  const conceptsStore = namespace('concepts');

  @Component
  export default class LogFilters extends Vue {
    locationId = 0;
    conceptsIds: number[] = [];
    lastId = 0;
    userId = 0;
    orderId = 0;
    types: LogType[] = [];

    @conceptsStore.State
    readonly conceptsForUser!: ConceptModel[];

    get locations(): TextValuePair[] {
      return getLocationsFromConceptList(this.conceptsForUser);
    }

    get concepts(): TextValuePair[] {
      if (!this.locationId) {
        return [];
      }

      return getConceptsForLocation(this.conceptsForUser, this.locationId);
    }

    get allTypes(): TextValuePair[] {
      return Object.keys(LogType).map(item => ({ text: item, value: item }));
    }

    update(): void {
      const filters: LogFiltersOptions = {
        locationId: this.locationId ? this.locationId : undefined,
        conceptsIds: this.conceptsIds,
        lastId: this.lastId ? this.lastId : undefined,
        userId: this.userId ? this.userId : undefined,
        orderId: this.orderId ? this.orderId : undefined,
        types: this.types
      };

      this.$emit('updated', filters);
    }
  }
